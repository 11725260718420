import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import Slider from 'react-slick';
import rehypeRaw from 'rehype-raw';

import '../common/casesection.css';
import CloudinaryImage from '../common/CloudinaryImage';

import Container from './Container';
import 'styles/screenReader.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
function CaseSection({ ourwork, writetous }) {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const settings = {
    dots: false,
    // infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 2000,
    // pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="caseslider relative z-[1]">
      <Container className={ourwork?.titleEditor && 'xl:mb-[34px] mb-[30px]'}>
        {ourwork?.titleEditor && (
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            className={`text-center font-[400] xl:text-[50px] md:text-[32px] text-[26px] title_hd leading-tight ${writetous && 'text-[#fff]'} mb-4`}
            source={ourwork?.titleEditor}
          >
            {ourwork?.titleEditor}
          </ReactMarkdown>
        )}
        {ourwork?.description && (
          <p
            className={`text-center text-[16px] font-[400] ${writetous && 'text-[#fff]'}`}
          >
            {ourwork?.description}
          </p>
        )}
      </Container>
      <div className="relative lg:h-[640px] h-[440px]">
        <Slider
          asNavFor={nav2}
          ref={slider => (sliderRef1 = slider)}
          fade={true}
          className="absolute top-0 bottom-0 left-0 right-0 z-[-1] h-full w-full"
        >
          {ourwork?.TopWork?.map(items => (
            <div key={items?.id}>
              {items?.bgImage?.data?.attributes?.url && (
                <CloudinaryImage
                  backendImgUrl={items?.bgImage?.data?.attributes?.url}
                  alt={items?.bgImage?.data?.attributes?.alternativeText}
                  className="w-full h-full object-cover mx-auto"
                  type="isDesktop"
                />
              )}
            </div>
          ))}
        </Slider>
        <div className="absolute top-0 bottom-0 left-0 right-0 h-full">
          <Slider
            asNavFor={nav1}
            ref={slider => (sliderRef2 = slider)}
            {...settings}
            swipeToSlide={true}
            focusOnSelect={true}
            className="h-full"
          >
            {ourwork?.TopWork?.map(items => (
              <div
                key={items?.id}
                aria-hidden="true"
                className='group h-full relative z-1 before:bg-[linear-gradient(180deg,_rgba(0,0,0,0.7),_rgba(0,0,0,0.7))] before:content-[""] before:bottom-0 before:left-0 before:absolute before:right-0 before:top-0'
              >
                <div className="md:p-[0_30px] p-[0_20px] absolute md:bottom-[100px]  bottom-32">
                  <p className="relative z-1 md:text-[30px] text-[25px] text-[#FFFFFF] font-[700] mb-3">
                    {items?.title}
                    <span className="block w-[60px] h-[4px] bg-[#ffa030]"></span>
                  </p>
                  <div className="current_active relative z-1 md:max-h-0 overflow-hidden  group-hover:max-h-[500px]  group-hover:pb-[10px]">
                    <p className="md:text-[16px] text-[16px] text-[#FFFFFF] font-[400] mb-4">
                      {items?.description}
                    </p>
                    <Link
                      href={`${items?.btn_link}`}
                      role="presentation"
                      className={` text-[16px] ${writetous ? 'bg-[#0050D5] border-[#0050D5] text-[#ffffff]' : 'bg-[#FFA030] border-[#D9D9D9] text-[#211200]'} font-[600] border rounded-[4px] py-2 px-4 inline-block`}
                    >
                      <div className="screen-reader-text">{`${items?.btn_link}`}</div>
                      {items?.btn_text}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default CaseSection;
